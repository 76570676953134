<template>
    <div class="holder">
        <div class="mt-3 mb-3">
            <span style="cursor: pointer;" @click="goBack()" class="mr-2"><i class="fa fa-arrow-left"></i></span>
            <span style="cursor: pointer;" @click="goBack()">Back</span>
        </div>


        <div v-if="loadingProperty" class="m-auto text-center mt-4">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <!-- <div class="empty text-center mt-4 " v-if="propDetails == null && !loadingProperty">No Record</div> -->
        <div  v-else>
            <div class="image-gallerey-view">
                <div class="row">
                    <div class="col-md-6" v-if="propDetails.images">
                        <div class="main-img">
                            <img class="w-100" :src="propDetails.images[0]" alt="main-image">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" v-if="propDetails.images">
                            <div class="col-md-6 mb-2" v-for="(item, index) in propDetails.images.slice(1, 5)" :key="index">
                                <!-- :class="[index == 4 ? 'last-img' : '']" -->
                                <div class="small-img" :class="{ 'last-img': index === 3 }">
                                    <h6 class="number-text" v-if="index == 3">+ {{ propDetails.images.length }}</h6>
                                    <img class="w-100" :src="item" alt="others">
                                    <!-- <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="others"> -->
                                </div>
                            </div>
                            <!-- <div class="col-md-6 mb-2">
                                <div class="small-img">
                                    <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="main-image">
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="small-img">
                                    <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="main-image">
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="small-img last-img">
                                    <h6 class="number-text">+ 10</h6>
                                    <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="main-image">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="prop-detail-holder mt-3">
                <div class="title-section">
                    <h4 class="title"> {{propDetails.title}}</h4>
                    <h6><img src="../assets/icons/location.svg" alt="location"> {{propDetails.city}}, {{propDetails.state}}</h6>
                </div>
                <div class="prop-price-spec">
                    <div>
                        <p>Price</p>
                        <h6>₦{{ Number(propDetails.price).toLocaleString() }}</h6>
                    </div>
                    <div>
                        <p>Bedrooms</p>
                        <h6>{{ propDetails.bedrooms }}</h6>
                    </div>
                    <div>
                        <p>Bathrooms</p>
                        <h6>{{ propDetails.bathrooms }}</h6>
                    </div>
                    <div>
                        <p>Property type</p>
                        <h6>{{propDetails.businesstype == 1 ? (propDetails.businesstype == 2 ? 'For Sale' : 'For Rent') : ''}}</h6>
                    </div>
                    <div>
                        <p>Square feet</p>
                        <h6>{{propDetails.squarefootage}}</h6>
                    </div>
                </div>
            </div>

            <div class="description-holder">
                <h4 class="header-text-top">Description</h4>
                <h6>{{propDetails.description}}</h6>
                <!-- <p>See More...</p> -->
            </div>

            <div class="offerings-holder mt-4">
                <div class="row">
                    <div class="col-md-6">
                        <div class="">
                            <h4  class="header-text-top">Offerings available here</h4>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 offering-list" v-for="facility in facilities" :key="facility">
                                    <p>{{facility}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="adddress-holder mt-4" v-if="propDetails.address">
                <img src="../assets/icons/location.svg" alt="location">
                <div class="prop-address">
                    <h5>Property address</h5>
                    <h6 v-if="propDetails">{{propDetails.address}}</h6>
                </div>
            </div>

            <div class="description-holder d-none">
                <h4 class="header-text-top">Terms & conditions</h4>
                <h6>Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla 
                    laoreet vel nullam scelerisque nulla aliquam sed.
                    Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                    Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet 
                    vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst.
                    In adipiscing congue id sit Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices
                    porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                </h6>
                <p data-toggle="modal" data-target="#termscondition">See More...</p>
            </div>

            <div class="text-center mt-5 d-none">
                <button
                    class="btn btn-primary share-btn"
                    @click="socialShare(propDetails)"
                >
                    <img
                    class="share-btn-img"
                    src="../assets/images/share.svg"
                    alt="share"
                    />
                </button>
            </div>

            <div class="modal fade" id="share-modal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-body text-center">
                        <p>Please select a network below to share this property.</p>
                        <div class="d-flex flex-column">
                        <ShareNetwork
                            class="mb-3"
                            network="email"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="far fa-envelope mr-2"></i>
                            <span>Share on Email</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="whatsapp"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-whatsapp mr-2"></i>
                            <span>Share on WhatsApp</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="twitter"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class=" btn btn-outline-dark">
                            <i class="fab fa-twitter mr-2"></i>

                            <span>Share on Twitter</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="facebook"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-facebook-f mr-2"></i>
                            <span>Share on Facebook</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="linkedin"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-linkedin-in mr-2"></i>
                            <span>Share on LinkedIn</span>
                            </button>
                        </ShareNetwork>
                        </div>

                        <div>
                        <button
                            type="button"
                            class="btn btn-secondary float-right"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- terms and condtion modal starts -->
        <div class="modal fade" tabindex="-1" id="termscondition">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="modal-top-text">Terms & Condtions</p>

                        <h6 class="modal-content-body">
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                            Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                            Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                        </h6>
                    </div>
               
                </div>
            </div>
        </div>
        <!-- terms and condtion modal ends -->
    </div>
</template>


<script>
    import properties from '../services/properties';
    import $ from "jquery";
    import "@fortawesome/fontawesome-free/css/all.css";
    // import Loader from '../../components/Loader.vue';
    export default {
        name: 'PropertyDetails',
        component: {
            // Loader
        },
        data() {
            return {
                loadingProperty: false,
                propDetails: [],
                oppid: "",
                facilities: [],
                userCode: [],
                sharing: {
                    url: "",
                    title: "",
                    description: "",
                    quote: "",
                    hashtags: ""
                },
                networks: [
                    {
                    network: "email",
                    name: "Email",
                    icon: "far fah fa-lg fa-envelope",
                    color: "#333333"
                    }
                ],
            }
        },
        computed: {
            
        },
        created() {
            
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            async propertyDetails() {
                this.loadingProperty = true;
                this.oppid = this.$route.params.id;
               await properties.fetchAgentPropertiesById(this.oppid).then(data => {
                    console.log("Property:", data);
                    this.loadingProperty = false;
                    if (data.success) {
                        this.propDetails = data.property;
                        this.facilities = JSON.parse(data.property.facilities);
                        console.log("Fcailitiessss", this.facilities);
                        // var x = document.getElementById("myImg").complete;
                        // document.getElementById("demo").innerHTML = x;
                        // this.$refs.myModal.classList.remove("open");
                    } else {
                        this.$toast.error(data.error);
                    }
                }).catch(() => {
                    this.loadingProperty = false;
                    this.$toast.error("please check your network and refresh the page");
                });
            },
            socialShare(property) {
                console.log("property pin,", property)
                // this.sharing.url = `https://smart.myshelta.com/user/opportunity-property/${property.id}`;
                if (property.type === "To Let") {
                    // this.sharing.url = `https://smarttest.myshelta.com/user/opportunity-property/${property.id}`;
                    this.sharing.url = `https://myshelta.com/property/${property.id}`;
                } else {
                    this.sharing.url = `https://sales.myshelta.com/property-details/${property.pin}/${this.userCode}`;
                    // this.sharing.url = `https://sales.myshelta.com/property-details/${property.id}/${this.userCode}`;
                }

                this.sharing.title = ` ${property.title} on Shelta. Click to view details.`;
                this.sharing.description =
                    "We provide a seamless and convenient rental and facility management experience. Too often, renting a home puts property owners and renters in difficult situations regarding their relationship. Shelta offers well-researched, thought out strategies to make an otherwise difficult experience pleasurble for both parties.";

                $("#share-modal").modal("show");
            },
        },
        mounted() {
            this.propertyDetails(); 
            this.userCode = this.$store.getters.getUser.usercode;
        },
    }

</script>


<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

.main-img {
    img {
        border-radius: 8px;
        height: 100%;
        max-height: 438px;
        min-height: 400px;
        object-fit: cover;
        // height: 195px;
    }
}

.small-img {
    // min-height: 215px;
    // height: 100%;
    // width: 100%;
    img {
        border-radius: 8px;
        // max-height: 215px;
        object-fit: cover;
        // max-height: 215px;
        // height: 100%;
        min-height: 195px;
        max-height: 215px;
    }
}

// .last-img {
//     // background-color: rgba(0, 0, 0, 0.5);
//     background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(0, 0, 0, 0.5);
// }
.last-img {
    position: relative;
}
.last-img::before {
    border-radius: 8px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    pointer-events: none; /* Allow interactions with the underlying image */
}

.number-text {
    position: absolute;
    top: 35%;
    left: 35%;
    color: $white;
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 54px;
}

.title-section {
    h4 {
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        color: #2B3352
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
    }
}
.prop-price-spec {
    border: 1px solid #E2E8F0;
}

.header-text-top {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #2B3352;
}

.prop-price-spec {
    display: grid;
    // justify-content: space-between;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding: 20px 40px;
    border-radius: 8px;
    margin-top: 20px;
    overflow: scroll;
}
.prop-price-spec > div {
    border-right: 1px solid #E2E8F0;
    padding-left: 20px;
    padding-right: 20px;
}
.prop-price-spec > div:first-child {
    padding-left: 0;
}
.prop-price-spec > div:last-child {
    border-right: none;
}
.prop-price-spec > div {
    p {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
        margin-bottom: 15px !important;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
    }
}

.description-holder {
    margin-top: 30px;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: justify;
        color: $asheGrey;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: left;
        color: #000A2F;
        text-decoration: underline;
        cursor: pointer;
    }
}

.offerings-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
}

.offering-list {
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: $asheGrey;
        border: 1px solid #555C74;
        border-radius: 50px;
        width: fit-content;
        padding: 0 15px;
    }
}

.adddress-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.prop-address {
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 100;
        line-height: 21px;
        text-align: left;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 599px) {
  .main-img {
    margin-bottom: 10px;
    img {
        max-height: 215px;
        object-fit: cover;
    }
  }
}

.stat-img {
  width: 25px;
}
.share-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0033ea;
  border-color: #0033ea;
}
.share-btn-img {
  width: 20px;
}

.modal-top-text {
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}
.modal-content-body {
    margin-top: 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 200;
    line-height: 24px;
    text-align: justified !important;

}


// Loader starts
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader ends
</style>


